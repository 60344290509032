const firebaseConfig = {
  apiKey: "AIzaSyBb3yUtYOLIOLNofsf7QY1jnTQS-ff1HQY",
  authDomain: "packet-demo-2ca7d.firebaseapp.com",
  databaseURL: "https://packet-demo-2ca7d.firebaseio.com",
  projectId: "packet-demo-2ca7d",
  storageBucket: "packet-demo-2ca7d.appspot.com",
  messagingSenderId: "209730630647",
  appId: "1:209730630647:web:bbe93876656ba4bf62b4f2"
};

export default firebaseConfig;
